<a
  *ngIf="!object; else objectTmp"
  [class.disabled]="disabled">
  <cl-icon
    [icon]="icon"
    [size]="size"></cl-icon>
  <span
    *ngIf="notificationsCount"
    class="notificationsCount">
    {{ notificationsCount }}
  </span>
</a>

<ng-template #objectTmp>
  <a [class.disabled]="disabled">
    <object
      type="image/svg+xml"
      data="/assets/icons/bell-blue-2.svg"
      [ngStyle]="{ width: size }">
      <img
        aria-hidden="true"
        src="/assets//icons/bell-blue-2.svg"
        [alt]="'Badge icon.' | translate"
        [ngStyle]="{ width: size }" />
    </object>
    <span
      *ngIf="notificationsCount"
      class="notificationsCount">
      {{ notificationsCount }}
    </span>
  </a>
</ng-template>
