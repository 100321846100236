import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Icon } from '@wam/icons';

@Component({
  selector: 'cl-icon-badge',
  templateUrl: './icon-badge.component.html',
  styleUrls: ['./icon-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconBadgeComponent {
  @Input() icon: Icon;
  @Input() size = '36px';
  @Input() object = true;
  @Input() notificationsCount: number;
  @Input() @HostBinding('class.disabled') disabled = false;

  Icon = Icon;
}
