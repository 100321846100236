<span
  class="itemWrapper"
  [class.disabled]="disabled"
  [class.selected]="selected"
  [class.upstart]="isUpstart"
  [id]="'tab-' + icon">
  <cl-icon
    size="40px"
    aria-hidden="true"
    [icon]="icon"></cl-icon>
  <span class="linkTitle">
    <ng-content></ng-content>
  </span>
  <span
    *ngIf="newItemsCount"
    class="newItemsCount">
    {{ newItemsCount }}
  </span>
</span>
