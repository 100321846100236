import { Component, Input } from '@angular/core';
import { Icon } from '@wam/icons';
import { Theme, ThemingService } from '@wam/theming';

@Component({
  selector: 'cl-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
})
export class NavItemComponent {
  @Input() icon: Icon;
  @Input() newItemsCount: number;
  @Input() selected: boolean;
  @Input() disabled: boolean;

  constructor(private readonly themingService: ThemingService) {}

  get isUpstart(): boolean {
    return this.themingService.theme === Theme.UPSTART;
  }
}
